@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

#App {
  font-family: 'Nunito', sans-serif;
  --color-tran-speed: 1s;
}

@font-face {
  font-family: LeckerliOne;
  src: url(fonts/LeckerliOne-Regular.ttf);
}

.preload * {
  -webkit-transition: none !important;
  -moz-transition: none !important;
  -ms-transition: none !important;
  -o-transition: none !important;
}

h1 {
  font-size: 96px;
  letter-spacing: -.08rem;
  color: var(--dark2);
  transition: color var(--color-tran-speed);
  margin-bottom: 0;
  font-weight:900;
  line-height: 1;

  @media (max-width: 600px) {
    font-size: 60px;
  }
}

.container {
  margin: 0 auto;
  max-width: min(1245px, 90%);
  position: relative;
}

.accent-line {
  height: 4px;
  width: 15rem;
  background-color: var(--dark2);
  transition: background-color var(--color-tran-speed);
  border-radius: 99px;
  margin: 1rem 0;
}

.sub-title {
  color: var(--dark2);
  transition: color var(--color-tran-speed);
  font-weight: 700;
  font-size: 40px;
  margin-top: 0;
  line-height: 1;
}

button {
  border-radius: 99px;
  border: none;
  text-align: center;
  padding: 10px 20px;
  background-color: white;
  color: var(--dark2);
  transition: color var(--color-tran-speed);
  font-weight: 700;
  &:hover {
    cursor: pointer;
    
  }
}

b {
  font-weight: bolder;
}

.right {
  position: absolute;
  right: 0;
}

.flex {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

a {
  text-decoration: none;
  color: var(--dark2);
  transition: color var(--color-tran-speed);
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.svg-stroke {
  stroke: var(--dark);
  transition: stroke var(--color-tran-speed);
}

.svg-fill-accent {
  fill: rgba(var(--accent), 0.5);
  transition: fill var(--color-tran-speed);
}

hr {
  margin: 5rem 0;
}

#user-guide {
  & > * {
    z-index: 1900; 
    animation-name: guide-anim;
    animation-duration: 1s;
    animation-delay: .25s;
    animation-fill-mode: forwards;
    opacity: 0;
  }

  .arrow-icon {
    position: absolute;
    top: 100px;
    left: 50px;
    filter: drop-shadow(0 5px 5px rgba(0, 0, 0, .3));

    path {
      fill: white;
      stroke: white;
    }
  }

  .guide-text {
    cursor: default;
    font-family: LeckerliOne;
    position: absolute;
    top: 144px;
    left: 228px;
    width: 190px;
    font-size: 23px;
    color: white;
    text-shadow: 0 5px 5px rgba(0,0,0,.3);

    @media (max-width: 500px) {
      left: 125px;
    }
  }
}

.footer {
  margin: 200px auto 20px;
  width: fit-content;
  font-family: LeckerliOne;
  a {
    color: var(--dark2);
    position: relative;
    &:hover {
      color: var(--main);
    }
  }
}

@keyframes guide-anim {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
  }
}