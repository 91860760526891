#about-section {
  padding-top: 10rem;

  .section-text {
    position: relative;
    width: min(100%, 900px);
    margin: 0 auto;
    &::before {
      content: "";
      display: inline-block;
      position: absolute;
      width: 175px;
      height: 175px;
      border-radius: 100%;
      background-color: rgba(var(--accent), 0.15);
      transition: background-color var(--color-tran-speed);
      margin: 0 auto;
      top: -38px;
      left: -54px;
      z-index: -1;
    }
  }

  p {
    margin: 18px auto;
    line-height: 1.8;
    font-weight: 700;
    color: var(--dark2);
    transition: color var(--color-tran-speed);
  }

  ul {
    display: flex;
    justify-content: center;
    margin: 0 auto;
    font-weight: 700;
    color: var(--dark2);
    transition: color var(--color-tran-speed);
    margin-top: 2rem;
    flex-wrap: wrap;
    gap: 3rem;
  }

  li {
    position: relative;

    &::before {
      content: "";
      display: inline-block;
      position: absolute;
      width: 20px;
      height: 20px;
      border-radius: 100%;
      background-color: rgba(var(--accent), 0.37);
      transition: background-color var(--color-tran-speed);
      margin: 0 auto;
      bottom: -0rem;
      top: 0;
      left: -4px;
      z-index: -1;
    }
  }
}