@import 'src/Styles.scss';

#home {
  .container {
    z-index: 1000;
  }
}

.main-banner {
  background-color: var(--main);
  transition: background-color var(--color-tran-speed);
  overflow: hidden;
  padding-top: 5.33333rem;
  position: relative;
  padding-bottom: 3rem;
  height:34rem;
}

.banner-left {
  padding-top: 4rem;
  p {
    font-family: LeckerliOne;
  }
}

button {
  font-size: 24px;
  color: var(--dark2);
  transition: color var(--color-tran-speed);
  padding: 15px 60px;
  font-weight: 700;
  cursor: pointer;
  transition: all .35s;
  margin-top: 1rem;

  &:hover {
    color: white;
    background-color: var(--dark2);
    transition: background-color var(--color-tran-speed);
  }
}
