#projects-section {
  padding-top: 10rem;
}

.projects {
  box-sizing: border-box;
  display: flex;
  flex: 0 1 auto;
  flex-direction: row;
  flex-wrap: wrap;
  list-style: none;
  margin: 8rem 0 0 -0.5rem;
  padding: 0;
  width: 100%;
}

.flex-item {
  flex-basis: 50%;
  max-width: 50%;
  &:nth-child(odd) {
    .project {
      margin-top: -2rem;
      margin-left: 5rem;
      margin-right: 2.5rem;
    }
  }
  &:nth-child(even) {
    .project {
      margin-top: 2rem;
      margin-left: 2.5rem;
      margin-right: 5rem;
    }
  }
}

.project {
  cursor: pointer;
  position: initial;
  transition: transform 0.25s cubic-bezier(0.645, 0.045, 0.355, 1) 0.1s;
  img {
    border-radius: 1rem;
    width: 100%;
    box-shadow: 0px 2px 6px rgba(0,0,0,.2);
    transition: box-shadow 0.25s cubic-bezier(0.645, 0.045, 0.355, 1) 0.1s;
  }

  h3 {
    font-size: 32px;
    font-weight: bold;
    margin-top: 20px;
    margin-bottom: 0px;
    transition: color .25s;
    color: var(--dark);
    transition: color var(--color-tran-speed);

    &::after {
      content: "";
      width: 100%;
      display: block;
      width: 0px;
      height: 2px;
      position: relative;
      bottom: 3px;
      background-color: var(--main);
      transition: color var(--color-tran-speed);
      opacity: 0.5;
      transition: width 0.25s cubic-bezier(0.645, 0.045, 0.355, 1) 0.1s;
    }
  }

  &:hover {
    transform: translateY(-10px);
    h3 {
      color: var(--main);
      transition: color var(--color-tran-speed);
    }
    h3::after {
      width: 100%;
    }
    img {
      box-shadow: 0px 5px 15px rgb(151, 151, 151);
    }
  } 

  p {
    line-height: 1.5;
    font-size: 20px;
    margin-top: 10px;
  }
}

@media (max-width: 1100px) {
  .flex-item {
    &:nth-child(odd) {
      .project {
        margin-top: -2rem;
        margin-left: 0rem;
        margin-right: 1rem;
      }
    }

    &:nth-child(even) {
      .project {
        margin-top: 2rem;
        margin-left: 1rem;
        margin-right: 0rem;
      }
    }
  }
}

@media (max-width: 700px) {
  .projects {
    flex-direction: column;
    margin: 8rem 0 0 0;
  }
  .flex-item {
    max-width: 100%; 
    flex-basis: 100%;
    &:nth-child(odd), &:nth-child(even) {
      .project {
        margin: 0 0 5rem 0;
      }
    }
  }
}