#jobs-section {
  padding-top: 10rem;
  --tab-height: 52px;
  --offset: 5px;

  #jobs-list {
    display: flex;
    flex-direction: column;
    align-items:flex-end;
    width: fit-content;
    gap: 0;
    ul {
      padding: 0;
    }
    li {
      padding: 15px 10px;
      text-align: right;
      margin: var(--offset) 0;
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
      @media (max-width: 550px) {
        border-radius: 5px;
      }
      color: var(--dark2);
      transition: color .2s;
      font-weight: 700;
      cursor: pointer;
      &:hover, &.selected {
        background-color: rgba(var(--accent), 0.1);
      }
      transition: all .25s;
      white-space: nowrap;
    }

    @media (max-width: 550px) {
      flex-direction: row;
    }
  }
}

.jobs-selector {
  border-right: 5px solid var(--main);
  transition: border-right var(--color-tran-speed);
  width: fit-content;
  height: fit-content;
  position: relative;
  padding: 10px 0;

  &::before, &::after {
    content: "";
    display: inline-block;
    position: absolute;
    width: 5px;
    height: 5px;
    border-radius: 100%;
    background-color: var(--main);
    right: -5px;
  }

  &::before {
    top: -3px;
  }
  &::after {
    bottom: -3px;
  }

  @media (max-width: 550px) {
    &::before, &::after {
      display: none;
    }
    border-right: none;
    margin: 0 auto 15px;
    border-bottom: 5px solid var(--main);
    transition: border-bottom var(--color-tran-speed);
  }
}

.jobs-content {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: min(100%, 1100px);
  margin: 0 auto;
  margin-top: 3rem;
}

.desk-icon {
  margin-top: 80px;
}

.job-text {
  width: 450px;

  @media (max-width: 670px) {
    width: 100%;
  }

  ul {
    padding-left: 25px;
  }
  li {
    position: relative;
    margin-bottom: 15px;
    color: var(--dark);
    transition: color var(--color-tran-speed);
    font-weight: 700;
    &::before {
      content: "";
      display: inline-block;
      position: absolute;
      width: 20px;
      height: 20px;
      border-radius: 100%;
      background-color: rgba(var(--accent), 0.37);
      margin: 0 auto;
      bottom: -0rem;
      top: 0;
      left: -4px;
      z-index: -1;
    }
  }
}

.jobs-container {
  display: flex;
  @media (max-width: 550px) {
    flex-direction: column;
  }
}

.selected-job {
  padding-left: 15px;
  transition: opacity 0.4s ease-in-out;
  height: 300px;
  @media (max-width: 500px) {
    height: 400px;
    .company-link {
      display: block;
      margin-left: -8px;
    }
    ul {
      padding-left: 0;
    }
  }
  h3 {
    font-size: 24px;
    margin: 5px 0 0 0;
    span:nth-child(1) {
      color: var(--dark2);
      transition: color var(--color-tran-speed);
    }
    span:nth-child(2) {
      color: var(--main);
      transition: color var(--color-tran-speed);
    }
  }

  a {
    cursor: pointer;
    color: var(--main);
    display: inline-block;
    
    &::after {
      content: "";
      width: 100%;
      display: block;
      width: 0px;
      height: 2px;
      position: relative;
      bottom: 3px;
      background-color: var(--main);
      opacity: 0.5;
      transition: width 0.25s cubic-bezier(0.645, 0.045, 0.355, 1) 0.1s;
    }
    &:hover::after {
      width: 100%;
    }
  }

  p {
    margin: 0 0 20px;
    font-size: 14px;
  }
}

.selected-marker {
  width: 5px;
  height: var(--tab-height);
  background-color: var(--dark);
  position: absolute;
  right: -5px;
  transition: transform 0.25s cubic-bezier(0.645, 0.045, 0.355, 1) 0.1s, color var(--color-tran-speed);
  @media (max-width: 550px) {
    display: none;
  }
}

@media (max-width: 1100px) {
  .jobs-content {
    flex-direction: column;
    align-items: center;
  }
  .desk-icon {
    margin-top: 0;
    margin-bottom: 5rem;
    scale: .8;
  }
}